import { ZoomMtg } from "@zoom/meetingsdk";
import { datadogLogs } from "@datadog/browser-logs";

import {
  ZoomBreakoutRoomInfo,
  ZoomCaptionEvent,
  ZoomMeetingInfo,
  ZoomParticipantInfo,
} from "./types/events";

export function preLoadWasm(): void {
  ZoomMtg.preLoadWasm();
}

export function prepareWebSDK(): void {
  ZoomMtg.prepareWebSDK();
}

export function init(leaveUrl: string) {
  return new Promise<void>((resolve, reject) => {
    ZoomMtg.init({
      debug: import.meta.env.MODE === "development",
      disableCallOut: true,
      disableInvite: true,
      disableZoomLogo: true,
      disableJoinAudio: true,
      disableReport: true,
      disableRecord: true,
      disablePreview: true,
      disableZoomPhone: true,
      disablePictureInPicture: true,
      disableVoIP: true,
      isSupportNonverbal: false,
      isSupportPolling: false,
      leaveUrl: leaveUrl,
      success: () => {
        resolve();
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function joinMeeting(
  sdkKey: string,
  meetingId: string,
  signature: string,
  userName: string,
  userEmail: string,
  tk?: string,
  zak?: string,
  passcode?: string,
) {
  return new Promise<void>((resolve, reject) => {
    // Strip "+notetaker@charliehealth.com" from the end of userEmail if present
    const domainToRemove = "+notetaker@charliehealth.com";
    const customerKey = userEmail.endsWith(domainToRemove)
      ? userEmail.slice(0, -domainToRemove.length)
      : userEmail;
    ZoomMtg.join({
      sdkKey: sdkKey,
      signature: signature,
      meetingNumber: meetingId,
      userName: userName,
      userEmail: userEmail,
      tk: tk,
      zak: zak,
      passWord: passcode,
      customerKey: customerKey,
      success: () => {
        resolve();
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function getCurrentMeetingInfo(): Promise<ZoomMeetingInfo> {
  return new Promise((resolve, reject) => {
    ZoomMtg.getCurrentMeetingInfo({
      success: (data: ZoomMeetingInfo) => {
        resolve(data);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function getParticipants(): Promise<ZoomParticipantInfo> {
  return new Promise((resolve, reject) => {
    ZoomMtg.getAttendeeslist({
      success: (data: ZoomParticipantInfo) => {
        resolve(data);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function getBreakoutRoomInfo(): Promise<ZoomBreakoutRoomInfo> {
  return new Promise((resolve, reject) => {
    ZoomMtg.getCurrentBreakoutRoom({
      success: (data: ZoomBreakoutRoomInfo) => {
        resolve(data);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function listenForCaptions(callback: (data: ZoomCaptionEvent) => void) {
  ZoomMtg.inMeetingServiceListener(
    "onReceiveTranscriptionMsg",
    function (data: ZoomCaptionEvent) {
      callback(data);
    },
  );
}

export function listenForStatusChanges() {
  // Temporary logging to possibly help debug Connect disconnecting issues
  ZoomMtg.inMeetingServiceListener("onMeetingStatus", function (data: unknown) {
    // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
    datadogLogs.logger.info(`onMeetingStatus ${JSON.stringify(data)}`);
  });
  ZoomMtg.inMeetingServiceListener(
    "onUserJoin",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (data: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (data.userName?.startsWith("Notetaker for")) {
        datadogLogs.logger.info(`onUserJoin ${JSON.stringify(data)}`);
      } else {
        datadogLogs.logger.info(
          `onUserJoin ${JSON.stringify({ ...data, userName: "Not the Notetaker" })}`,
        );
      }
    },
  );
  ZoomMtg.inMeetingServiceListener(
    "onUserLeave",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (data: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (data.userName?.startsWith("Notetaker for")) {
        datadogLogs.logger.info(`onUserLeave ${JSON.stringify(data)}`);
      } else {
        datadogLogs.logger.info(
          `onUserLeave ${JSON.stringify({ ...data, userName: "Not the Notetaker" })}`,
        );
      }
    },
  );
  ZoomMtg.inMeetingServiceListener(
    "onNetworkQualityChange",
    function (data: unknown) {
      // {level: 0 || 1 || 2 || 3 || 4 || 5, userId, type: 'uplink' }
      // 0,1 => bad; 2 => normal; 3,4,5 => good;
      datadogLogs.logger.info(`onNetworkQualityChange ${JSON.stringify(data)}`);
    },
  );
}

export function joinBreakoutRoom(breakoutRoomId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    ZoomMtg.joinBreakoutRoom({
      roomId: breakoutRoomId,
      success: () => {
        resolve();
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}
